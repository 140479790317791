import "./App.css";

import Orbit from "./partials/Orbit";
import About from "./partials/About";
import Hero from "./partials/Hero";
import Grid from "./partials/Grid";
import Features from "./partials/Features";
import SubFeatures from "./partials/SubFeatures";
import Leaderboard from "./partials/Leaderboard";
import User from "./partials/User";
import Clients from "./partials/Clients";
import Support from "./partials/Support";
import Blog from "./partials/Blog";
import Cta from "./partials/Cta";
import Newsletter from "./partials/Newsletter";

function Home() {
  return (<>   
      {/* <Pricing /> */}
      
      <Orbit />
      
      <About />

      {/* <Features /> */}
      <SubFeatures />
      
      <Grid />
    
      <Cta />
      
      <User />
      
      <Clients />
      
      <Blog />
    
      <Support />
      
      <Newsletter />
      
      <Hero />
      
    </>);
}

export default Home;
