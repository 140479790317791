function Features() { 
return (<>
<section className="pt-12.5">
      <div className="max-w-[1170px] mx-auto px-4 sm:px-8 xl:px-0">
        <div className="grid sm:grid-cols-12 gap-7.5" data-highlighter>
          {/* <!-- features item --> */}
        
          {/* <!-- features item --> */}
          <div className="sm:col-span-7">
            <div className="relative rounded-3xl features-box-border">
              <div className="relative overflow-hidden rounded-3xl px-11 pt-12.5 pb-14 box-hover box-hover-small">
                <div className="relative z-20">
                  <span
                    className="icon-border relative max-w-[80px] w-full h-20 rounded-full inline-flex items-center justify-center mb-13.5 mx-auto">
                    <img src={"./images/features/icon-05.svg"} alt="icon" />
                  </span>

                  <h3 className="text-white mb-4.5 font-semibold text-heading-6">
                    Launch your rune
                  </h3>
                  <p className="font-medium">
                    Ordbit is designed to empower you with
                    exceptional rune creation, minting & swapping, making the writing
                    process...
                  </p>
                </div>

                {/* <!-- bg shapes --> */}
                <div className="absolute -z-10 pointer-events-none inset-0 overflow-hidden">
                  <span className="absolute left-0 bottom-0">
                    <img src={"./images/blur/blur-06.svg"} alt="blur" className="max-w-none" />
                  </span>
                  <span className="absolute right-0 top-0">
                    <img src={"./images/blur/blur-07.svg"} alt="blur" className="max-w-none" />
                  </span>
                  <span className="absolute right-[16%] top-[16%]"><img src={"./images/features/shape-04.svg"}                      alt="shape" /></span>
                  <span className="absolute left-0 bottom-0">
                    <img src={"./images/blur/blur-08.svg"} alt="blur" className="max-w-none" />
                  </span>
                  <span className="absolute left-0 bottom-0">
                    <img src={"./images/blur/blur-09.svg"} alt="blur" className="max-w-none" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- features item --> */}
          <div className="sm:col-span-5">
            <div className="relative rounded-3xl features-box-border">
              <div className="relative overflow-hidden rounded-3xl px-11 pt-12.5 pb-14 box-hover box-hover-small">
                <div className="relative z-20">
                  <span
                    className="icon-border relative max-w-[80px] w-full h-20 rounded-full inline-flex items-center justify-center mb-13.5 mx-auto">
                    <img src={"./images/features/icon-07.svg"} alt="icon" />
                  </span>

                  <h3 className="text-white mb-4.5 font-semibold text-heading-6">
                    Mint & Swap Runes
                  </h3>
                  <p className="font-medium">
                    Ordbit is designed to empower you with
                    exceptional rune creation, minting & swapping.
                  </p>
                </div>

                {/* <!-- bg shapes --> */}
                <div className="absolute -z-10 pointer-events-none inset-0 overflow-hidden">
                  <span className="absolute right-[14%] top-[17%]"><img src={"./images/features/shape-05.svg"}                      alt="shape" /></span>
                  <span className="absolute left-0 bottom-0">
                    <img src={"./images/blur/blur-10.svg"} alt="blur" className="max-w-none" />
                  </span>
                  <span className="absolute top-0 right-0">
                    <img src={"./images/blur/blur-11.svg"} alt="blur" className="max-w-none" />
                  </span>
                  <span className="absolute top-0 right-0">
                    <img src={"./images/blur/blur-12.svg"} alt="blur" className="max-w-none" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</>);
}
export default Features;
