function Clients() { 
return (<>
<section className="py-19">
      <div className="max-w-[1104px] mx-auto px-4 sm:px-8 xl:px-0">
        <div className="relative overflow-hidden z-10">
          <span
            className="max-w-[128px] w-full h-[37px] block inset-0 pointer-events-none absolute z-10 left-0 top-1/2 -translate-y-1/2 bg-gradient-to-l from-dark/0 to-dark/100"></span>
          <span
            className="max-w-[128px] w-full h-[37px] block inset-0 pointer-events-none absolute z-10 left-auto top-1/2 -translate-y-1/2 bg-gradient-to-r from-dark/0 to-dark/100"></span>
          <div className="swiper clients-carousel">
            <div className="swiper-wrapper items-center select-none !ease-linear">
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-01.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-02.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-03.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-04.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-05.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-06.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img className="mt-3" src={"./images/clients/client-07.svg"} alt="client" />
                </a>
              </div>

              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-01.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-02.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-03.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-04.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-05.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-06.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img className="mt-3" src={"./images/clients/client-07.svg"} alt="client" />
                </a>
              </div>

              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-01.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-02.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-03.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-04.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-05.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img src={"./images/clients/client-06.svg"} alt="client" />
                </a>
              </div>
              <div className="swiper-slide !w-auto">
                <a href="/#">
                  <img className="mt-3" src={"./images/clients/client-07.svg"} alt="client" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</>);
}
export default Clients;
