import React from "react";
import ReactDOM from "react-dom/client";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import ScrollTop from "./partials/Scrolltop";
import Home from "./Home";
import Leaderboard from "./Leaderboard";
import "./index.css";
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const App = () => {
  return (
      <BrowserRouter basename="/">
      <div>
          <Header />
          <main>
              <Switch>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path="/leaderboard">
                    <Leaderboard />
                  </Route>
                  {/* <Route component={NotFoundPage} /> */}
              </Switch>
          </main>
          <Footer />
          <ScrollTop />
      </div>
      </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
