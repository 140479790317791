function Header() { 
return (<>
<header className="fixed left-0 top-0 w-full z-9999 py-7 lg:py-0"
  // :className="{ 'bg-dark/70 backdrop-blur-lg shadow !py-4 lg:!py-0 transition duration-100 before:absolute before:w-full before:h-[1px] before:bottom-0 before:left-0 before:features-row-border' : stickyMenu }"
  // @scroll.window="stickyMenu = (window.scrollY > 0) ? true : false"
  >
  <div className="max-w-[1170px] mx-auto px-4 sm:px-8 xl:px-0 lg:flex items-center justify-between relative">
    <div className="w-full lg:w-1/2 flex items-center justify-between">
      <a href="index.html" style={{float: "left;"}}>
        <img src={"./images/logo/logo.svg"} alt="Logo" style={{float:"left", height:"30px", width: "25%", marginTop: "10%"}} />
        <h2 className="text-white mb-6 text-3xl font-extrabold sm:text-5xl xl:text-heading-2" style={{float: "right", width: "75%"}}>
            Ordbit
        </h2>
      </a>

      {/* <!-- Hamburger Toggle BTN --> */}
      <button className="lg:hidden block" 
      // @click="navigationOpen = !navigationOpen"
      >
        <span className="block relative cursor-pointer w-5.5 h-5.5">
          <span className="du-block absolute right-0 w-full h-full">
            <span
              className="block relative top-0 left-0 bg-white rounded-sm w-0 h-0.5 my-1 ease-in-out duration-200 delay-[0]"
              // :className="{ '!w-full delay-300': !navigationOpen }"
              ></span>
            <span
              className="block relative top-0 left-0 bg-white rounded-sm w-0 h-0.5 my-1 ease-in-out duration-200 delay-150"
              // :className="{ '!w-full delay-400': !navigationOpen }"
              ></span>
            <span
              className="block relative top-0 left-0 bg-white rounded-sm w-0 h-0.5 my-1 ease-in-out duration-200 delay-200"
              // :className="{ '!w-full delay-500': !navigationOpen }"
              ></span>
          </span>
          <span className="du-block absolute right-0 w-full h-full rotate-45">
            <span
              className="block bg-white rounded-sm ease-in-out duration-200 delay-300 absolute left-2.5 top-0 w-0.5 h-full"
              // :className="{ '!h-0 delay-[0]': !navigationOpen }"
              ></span>
            <span
              className="block bg-white rounded-sm ease-in-out duration-200 delay-400 absolute left-0 top-2.5 w-full h-0.5"
              // :className="{ '!h-0 dealy-200': !navigationOpen }"
              ></span>
          </span>
        </span>
      </button>
      {/* <!-- Hamburger Toggle BTN --> */}
    </div>

    <div className="w-full lg:w-3/4 h-0 lg:h-auto invisible lg:visible lg:flex items-center justify-between"
      // :className="{ '!visible bg-dark shadow-lgrelative !h-auto max-h-[400px] overflow-y-scroll rounded-md mt-4 p-7.5': navigationOpen }"
      >
      <nav>
        <ul className="flex lg:items-center flex-col lg:flex-row gap-5 lg:gap-2">
          <li className="nav__menu lg:py-7" 
          // :className="{ 'lg:!py-4' : stickyMenu }"
          >
            <a href="/#features"
            className="relative text-white/80 text-sm py-1.5 px-4 border border-transparent hover:text-white hover:nav-gradient">Search</a>
          </li>
          <li className="nav__menu lg:py-7" 
          // :className="{ 'lg:!py-4' : stickyMenu }"
          >
            <a href="/#home"
              className="relative text-white/80 text-sm py-1.5 px-4 border border-transparent hover:text-white hover:nav-gradient"
              // :className="{'!text-white nav-gradient' :page === 'home'}"
              >Mint</a>
          </li>
          <li className="nav__menu lg:py-7" 
          // :className="{ 'lg:!py-4' : stickyMenu }"
          >
            <a href="/#pricing"
              className="relative text-white/80 text-sm py-1.5 px-4 border border-transparent hover:text-white hover:nav-gradient">Swap</a>
          </li>
          <li className="group relative lg:py-7" 
          // :className="{ 'lg:!py-4' : stickyMenu }" x-data="{ dropdown: false }"
          >
            <a href="#"
              className="relative text-white/80 text-sm py-1.5 px-4 border border-transparent hover:text-white hover:nav-gradient flex items-center justify-between gap-3"
              // @click.prevent="dropdown = !dropdown"
              // :className="{ '!text-white nav-gradient': page === 'about' || page === 'pricing' || page === 'blog-grid' || page === 'blog-single' || page === 'signin' || page === 'signup' || page === '404' }"
              >
              Launch

              <svg className="fill-current w-3 h-3 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
              </svg>
            </a>

            {/* <!-- Dropdown Start --> */}
            <ul className="dropdown" 
            // :className="{ 'flex': dropdown }"
            >
              <li>
                <a href="/about"
                  className="flex text-sm text-white/70 hover:text-white py-2 px-4 rounded-md hover:bg-white/5"
                  // :className="{ '!text-white bg-white/5': page === 'about' }"
                  >About Us</a>
              </li>
              <li>
                <a href="/testimonials"
                  className="flex text-sm text-white/70 hover:text-white py-2 px-4 rounded-md hover:bg-white/5"
                  // :className="{ '!text-white bg-white/5': page === 'pricing' }"
                  >Testimonials</a>
              </li>
              <li>
                <a href="/blog"
                  className="flex text-sm text-white/70 hover:text-white py-2 px-4 rounded-md hover:bg-white/5"
                  // :className="{ '!text-white bg-white/5': page === 'blog-grid' }"
                  >Blog</a>
              </li>
              
              <li>
               &nbsp;
              </li>
              <li>
                <a href="/enterprises"
                  className="flex text-sm text-white/70 hover:text-white py-2 px-4 rounded-md hover:bg-white/5"
                  // :className="{ '!text-white bg-white/5': page === 'signup' }"
                  >Launch</a>
              </li>
              <li>
                <a href="/contact"
                  className="flex text-sm text-white/70 hover:text-white py-2 px-4 rounded-md hover:bg-white/5"
                  // :className="{ '!text-white bg-white/5': page === '404' }"
                  >Contact</a>
              </li>
            </ul>
            {/* <!-- Dropdown End --> */}
          </li>
          <li className="nav__menu lg:py-7" 
          // :className="{ 'lg:!py-4' : stickyMenu }"
          >
            <a href="/#support"
              className="relative text-white/80 text-sm py-1.5 px-4 border border-transparent hover:text-white hover:nav-gradient">Support</a>
          </li>
        </ul>
      </nav>

      <div className="flex items-center gap-6 mt-7 lg:mt-0">
        
        {/* <a href="signup.html"
          className="button-border-gradient relative rounded-lg text-white text-sm flex items-center gap-1.5 py-2 px-4.5 shadow-button hover:button-gradient-hover hover:shadow-none">
          Connect Wallet

          <svg className="mt-0.5" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.4002 7.60002L9.2252 2.35002C9.0002 2.12502 8.6502 2.12502 8.4252 2.35002C8.2002 2.57502 8.2002 2.92502 8.4252 3.15002L12.6252 7.42502H2.0002C1.7002 7.42502 1.4502 7.67502 1.4502 7.97502C1.4502 8.27502 1.7002 8.55003 2.0002 8.55003H12.6752L8.4252 12.875C8.2002 13.1 8.2002 13.45 8.4252 13.675C8.5252 13.775 8.6752 13.825 8.8252 13.825C8.9752 13.825 9.1252 13.775 9.2252 13.65L14.4002 8.40002C14.6252 8.17502 14.6252 7.82503 14.4002 7.60002Z"
              fill="white" />
          </svg>
        </a> */}
        <button class="w-full flex items-center justify-center gap-3 p-3.5 font-medium text-white rounded-lg border border-white/[0.12] ease-in duration-300 hover:border-purple">
          Connect Wallet
        </button>
      </div>
    </div>
  </div>
</header></>);
}
export default Header;
