function Grid() { 
return (<>
<section id="features" className="overflow-hidden pt-17.5 lg:pt-22.5 xl:pt-27.5 scroll-mt-17">
      <div className="max-w-[1222px] mx-auto px-4 sm:px-8 xl:px-0">
        {/* <!-- Section Title --> */}
        <div className="wow fadeInUp text-center">
          <span
            className="hero-subtitle-gradient relative mb-4 font-medium text-sm inline-flex items-center gap-2 py-2 px-4.5 rounded-full">
            <img src={"./images/hero/icon-title.svg"} alt="icon" />

            <span className="hero-subtitle-text"> Some of Main Features </span>
          </span>
          <h2 className="text-white mb-4.5 text-2xl font-extrabold sm:text-4xl xl:text-heading-2">
            Key Features of Ordbit
          </h2>
          <p className="max-w-[714px] mx-auto mb-5 font-medium">
            Ordbit is designed to empower you with exceptional
            rune creation, minting & swapping, making the experience with runes  efficient, safe and a great experience,
            accurate, and enjoyable.
          </p>
        </div>

        <div className="relative">
          <div
            className="features-row-border rotate-90 w-1/2 h-[1px] absolute top-1/2 left-1/2 -translate-y-1/2 lg:-translate-x-1/3 lg:left-1/4 hidden lg:block">
          </div>
          <div
            className="features-row-border rotate-90 w-1/2 h-[1px] absolute top-1/2 right-1/2 -translate-y-1/2 lg:right-[8.3%] hidden lg:block">
          </div>

          {/* <!-- ==== Features Row === --> */}
          <div className="flex flex-wrap justify-center">
            {/* <!-- Features Item --> */}
            <div className="w-full sm:w-1/2 lg:w-1/3">
              <div className="group relative overflow-hidden text-center py-8 sm:py-10 xl:py-15 px-4 lg:px-8 xl:px-13">
                <span
                  className="group-hover:opacity-100 opacity-0 features-bg absolute w-full h-full left-0 top-0 -z-1"></span>
                <span
                  className="icon-border relative max-w-[80px] w-full h-20 rounded-full inline-flex items-center justify-center mb-8 mx-auto">
                  <img src={"./images/features/icon-01.svg"} alt="icon" />
                </span>
                <h4 className="font-semibold text-lg text-white mb-4">
                  Mint & Swap Runes
                </h4>
                <p className="font-medium">
                  Or create your own
                </p>
              </div>
            </div>

            {/* <!-- Features Item --> */}
            <div className="w-full sm:w-1/2 lg:w-1/3">
              <div className="group relative overflow-hidden text-center py-8 sm:py-10 xl:py-15 px-4 lg:px-8 xl:px-13">
                <span
                  className="group-hover:opacity-100 opacity-0 features-bg absolute w-full h-full left-0 top-0 -z-1"></span>
                <span
                  className="icon-border relative max-w-[80px] w-full h-20 rounded-full inline-flex items-center justify-center mb-8 mx-auto">
                  <img src={"./images/features/icon-02.svg"} alt="icon" />
                </span>
                <h4 className="font-semibold text-lg text-white mb-4">
                  Onchain FTW
                </h4>
                <p className="font-medium">
                  Say goodbye to offchain metadata on L2's
                </p>
              </div>
            </div>

            {/* <!-- Features Item --> */}
            <div className="w-full sm:w-1/2 lg:w-1/3">
              <div className="group relative overflow-hidden text-center py-8 sm:py-10 xl:py-15 px-4 lg:px-8 xl:px-13">
                <span
                  className="group-hover:opacity-100 opacity-0 features-bg absolute w-full h-full left-0 top-0 -z-1"></span>
                <span
                  className="icon-border relative max-w-[80px] w-full h-20 rounded-full inline-flex items-center justify-center mb-8 mx-auto">
                  <img src={"./images/features/icon-03.svg"} alt="icon" />
                </span>
                <h4 className="font-semibold text-lg text-white mb-4">
                  Onchain FTW
                </h4>
                <p className="font-medium">
                  Say goodbye to offchain metadata on L2's
                </p>
              </div>
            </div>
          </div>

          <div className="features-row-border w-full h-[1px]"></div>

          {/* <!-- === Features Row ===--> */}
          <div className="flex flex-wrap justify-center">
            {/* <!-- Features Item --> */}
            <div className="w-full sm:w-1/2 lg:w-1/3">
              <div className="group relative overflow-hidden text-center py-8 sm:py-10 xl:py-15 px-4 lg:px-8 xl:px-13">
                <span
                  className="group-hover:opacity-100 opacity-0 features-bg absolute w-full h-full left-0 top-0 -z-1 rotate-180"></span>
                <span
                  className="icon-border relative max-w-[80px] w-full h-20 rounded-full inline-flex items-center justify-center mb-8 mx-auto">
                  <img src={"./images/features/icon-04.svg"} alt="icon" />
                </span>
                <h4 className="font-semibold text-lg text-white mb-4">
                  Feature
                </h4>
                <p className="font-medium">
                  Description
                </p>
              </div>
            </div>

            {/* <!-- Features Item --> */}
            <div className="w-full sm:w-1/2 lg:w-1/3">
              <div className="group relative overflow-hidden text-center py-8 sm:py-10 xl:py-15 px-4 lg:px-8 xl:px-13">
                <span
                  className="group-hover:opacity-100 opacity-0 features-bg absolute w-full h-full left-0 top-0 -z-1 rotate-180"></span>
                <span
                  className="icon-border relative max-w-[80px] w-full h-20 rounded-full inline-flex items-center justify-center mb-8 mx-auto">
                  <img src={"./images/features/icon-05.svg"} alt="icon" />
                </span>
                <h4 className="font-semibold text-lg text-white mb-4">
                  Fast 
                </h4>
                <p className="font-medium">
                  Super fast
                </p>
              </div>
            </div>

            {/* <!-- Features Item --> */}
            <div className="w-full sm:w-1/2 lg:w-1/3">
              <div className="group relative overflow-hidden text-center py-8 sm:py-10 xl:py-15 px-4 lg:px-8 xl:px-13">
                <span
                  className="group-hover:opacity-100 opacity-0 features-bg absolute w-full h-full left-0 top-0 -z-1 rotate-180"></span>
                <span
                  className="icon-border relative max-w-[80px] w-full h-20 rounded-full inline-flex items-center justify-center mb-8 mx-auto">
                  <img src={"./images/features/icon-06.svg"} alt="icon" />
                </span>
                <h4 className="font-semibold text-lg text-white mb-4">
                  Balanced
                </h4>
                <p className="font-medium">
                  Right?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</>);
}
export default Grid;
