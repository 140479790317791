function Orbit() { 
return (<>
<section id="pricing" className="relative z-20 overflow-hidden pt-22.5 lg:pt-27.5 xl:pt-32.5 scroll-mt-17">
      <div className="max-w-[1170px] mx-auto sm:px-8 xl:px-0">
        {/* <!-- bg circles --> */}
        <div className="relative top-18">
          <div className="absolute -z-10 pointer-events-none inset-0 overflow-hidden -my-55">
            <div className="absolute left-1/2 -translate-x-1/2 top-0">
              <img src={"./images/blur/blur-13.svg"} alt="blur" className="max-w-none" />
            </div>
            <div className="absolute left-1/2 -translate-x-1/2 top-0">
              <img src={"./images/blur/blur-14.svg"} alt="blur" className="max-w-none" />
            </div>
            <div className="absolute left-1/2 -translate-x-1/2 top-0">
              <img src={"./images/blur/blur-15.svg"} alt="blur" className="max-w-none" />
            </div>
          </div>
          <div
            className="max-w-[830px] w-full h-[830px] rounded-full bg-dark absolute left-1/2 -translate-x-1/2 top-0 pricing-circle">
          </div>

          {/* <!-- section title --> */}
        <div className="wow fadeInUp mb-17.5 -mt-24 text-center z-10 relative">
          <span
            className="hero-subtitle-gradient relative mb-4 font-medium text-sm inline-flex items-center gap-2 py-2 px-4.5 rounded-full">
            <img src={"./images/hero/icon-title.svg"} alt="icon" />

            <span className="hero-subtitle-text"> Get access </span>
          </span>
          <h2 className="text-white mb-4.5 text-2xl font-extrabold sm:text-4xl xl:text-heading-2">
            The trusted partner for Bitcoin
          </h2>
          <p className="max-w-[714px] mx-auto font-medium">
            Launch your rune, pool on Ordbit and rely on the earliest players
            in the ordinals and runes ecosystem
          </p>
        </div>

        
          <div className="max-w-[482px] w-full h-60 overflow-hidden absolute -z-1 -top-30 left-1/2 -translate-x-1/2">
            <div className="stars"></div>
            <div className="stars2"></div>
          </div>
        </div>

      </div>
    </section>
</>);
}
export default Orbit;
