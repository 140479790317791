import "./App.css";

import Orbit from "./partials/Orbit";
import Leaderboard from "./partials/Leaderboard";

function Page() {
  return (<>   
      {/* <Pricing /> */}
      
      <Orbit />
      
      <Leaderboard />
      
    </>);
}

export default Page;
