function About () {
  return (<>
      <section class="overflow-hidden">
        <div class="max-w-[1170px] mx-auto px-4 sm:px-8 xl:px-0 py-20 lg:py-25 relative">
          <div class="absolute bottom-0 left-0 w-full h-[1px] about-divider-gradient"></div>

          <div class="flex gap-11 flex-wrap justify-between">
            <div class="wow fadeInLeft max-w-[570px] w-full">
              <span class="hero-subtitle-text font-semibold block mb-5">New</span>

              <h2 class="text-white mb-5 text-2xl font-extrabold sm:text-4xl xl:text-heading-2">
                Join the 10,000+ wallets waitlisted to interact with our DEX
              </h2>
              <p class="mb-9 font-medium">
                Ordbit is designed to empower you with exceptional
                rune creation, minting & swapping, making the experience with runes  efficient, safe and a great experience.
              </p>

              {/* <a href="#"
                class="hero-button-gradient inline-flex rounded-lg py-3 px-7 text-white font-medium ease-in duration-300 hover:opacity-80">
                Start Swapping
              </a> */}
              <button class="flex items-center justify-center gap-3 p-3.5 font-medium text-white rounded-lg border border-white/[0.12] ease-in duration-300 hover:border-purple">
                Join the Testnet
              </button>
            </div>

            <div class="wow fadeInRight hidden xl:block">
              <img src="./images/about/about.svg" alt="about" />
            </div>
          </div>
        </div>
      </section>
  </>);
}

export default About;