import {useState} from "react";

function Point ({title, points, description}) {
  const [opened, setOpen] = useState(false);
  return (
    <div class="flex flex-col border-b border-white/[0.15]">
    <h4 
    // @click="selected !== 0 ? selected = 0 : selected = null"
      class="text-white cursor-pointer flex justify-between items-center font-semibold text-[22px] leading-[28px] py-5 lg:py-7"
      // :class="selected == 0 ? 'text-white' : ''"
      >
       <div style={{float:"left",width:"100%"}}>
          <div style={{float:"left",width:"80%"}}>{title}</div>
          <div style={{float:"left", width:"20%"}}>{points} pts</div>
       </div>
      <span 
      // :class="selected == 0 ? 'hidden' : 'block'"
      >
      
      </span>

    </h4>
    <p x-show="selected == 0" class="font-medium pb-7">
      {description}
    </p>
  </div>

  )
}
function Page() { 
return (<>
<section id="leaderboard" className="relative z-20 overflow-hidden pt-22.5 lg:pt-27.5 xl:pt-32.5 scroll-mt-17">
      <div className="max-w-[1170px] mx-auto sm:px-8 xl:px-0">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-7.5">
          {/* <!-- Pricing item --> */}
          <div
            className="wow fadeInUp rounded-3xl bg-dark relative z-20 overflow-hidden pt-12.5 pb-10 px-8 xl:px-10 pricing-item-border">

            <h3 className="font-semibold text-heading-6 text-white mb-5.5">
              This week
            </h3>
   
            <div className="flex items-center gap-3.5">
              <h2 className="font-bold text-custom-1 pricing-gradient-text">
                Leaderboard
              </h2>

              <p className="font-medium">
                top <br />
                10
              </p>
            </div>

            
            <div className="my-10 w-full h-[1px] pricing-gradient-divider"></div>

            {/* <!-- bg shapes --> */}
            <div className="absolute -z-10 pointer-events-none inset-0 overflow-hidden">
              <span className="absolute left-0 bottom-0 -z-1">
                <img src={"./images/blur/blur-16.svg"} alt="blur" className="max-w-none" />
              </span>
              <span className="absolute left-0 top-0 -z-1">
                <img src={"./images/blur/blur-17.svg"} alt="blur" className="max-w-none" />
              </span>
            </div>

            <table class="flex flex-col gap-4">
                <tr class="flex justify-between items-center font-semibold text-[22px] leading-[28px] text-white" >
                  <td style={{textAlign:"left",width:"50%"}}>
                    user1
                  </td>
                  <td style={{textAlign:"left",width:"50%"}}>
                    100,124
                  </td>
                </tr>
                <tr class="flex justify-between items-center font-semibold text-[22px] leading-[28px] text-white" >
                  <td style={{textAlign:"left",width:"50%"}}>
                    user1
                  </td>
                  <td style={{textAlign:"left",width:"50%"}}>
                    100,124
                  </td>
                </tr>
                <tr class="flex justify-between items-center font-semibold text-[22px] leading-[28px] text-white" >
                  <td style={{textAlign:"left",width:"50%"}}>
                    user1
                  </td>
                  <td style={{textAlign:"left",width:"50%"}}>
                    100,124
                  </td>
                </tr>
                <tr class="flex justify-between items-center font-semibold text-[22px] leading-[28px] text-white" >
                  <td style={{textAlign:"left",width:"50%"}}>
                    user1
                  </td>
                  <td style={{textAlign:"left",width:"50%"}}>
                    100,124
                  </td>
                </tr>
                <tr class="flex justify-between items-center font-semibold text-[22px] leading-[28px] text-white" >
                  <td style={{textAlign:"left",width:"50%"}}>
                    user1
                  </td>
                  <td style={{textAlign:"left",width:"50%"}}>
                    100,124
                  </td>
                </tr>

              </table>


          </div>

          {/* <!-- Pricing item --> */}
          <div
            className="wow rounded-3xl bg-dark relative z-20 overflow-hidden pt-12.5 pb-10 px-8 xl:px-10 pricing-item-border">
            <div class="wow fadeInUp">
          <div x-data="{selected:0}">
           <Point title={"When you follow @ordbit"} points={500} description={"Follow us on x.com/@ordbit"}></Point>
           <Point title={"Connect your wallet"} points={1000} description={"When you connect your wallet"}></Point>
           <Point title={"Contribute to a pool"} points={1000} description={"Follow us on x.com/@ordbit"}></Point>
           <Point title={"Make a swap"} points={5000} description={"Follow us on x.com/@ordbit"}></Point>
          </div>
        </div>
        </div>
      </div>
    </div>
    </section>
</>);
}
export default Page;
